<template>
  <div class="container">
    <div class="title">当前位置：</div>
    <a-breadcrumb>
      <template #separator><span style="color: #fff">></span></template>
      <!-- <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item><a href="">Application Center</a></a-breadcrumb-item>
      <a-breadcrumb-item><a href="">Application List</a></a-breadcrumb-item>
      <a-breadcrumb-item>An Application</a-breadcrumb-item> -->
      <template v-for="(item, index) in matchedList" :key="index">
        <a-breadcrumb-item v-if="index === matchedList.length - 1" >{{ item.name }}</a-breadcrumb-item>    
        <a-breadcrumb-item v-else >
          <router-link :to="item.path">{{ item.name }}</router-link>
          <!-- {{ item.name }} -->
        </a-breadcrumb-item>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const matchedList = computed(() => {
    return route.matched
})

</script>

<style lang="less" scoped>
.container {
  margin-top: 10px;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 16px;
  background: linear-gradient(206deg, #eac0c0 0%, #dd3535 100%);
  border-radius: 25px;
  padding: 0 26px;
  box-sizing: border-box;
  color: #fff;
  .title {
  }
  li,
  li a,
  li .ant-breadcrumb-separator {
    color: #fff !important;
    font-size: 16px !important;
  }
}
</style>
