<template>
    <div class="backTop" @click="back" v-show="activeShow">
        <i></i>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
const activeShow = ref(false)
onMounted(() => {
    window.addEventListener("scroll", handleScroll, true);
})
const handleScroll = () => {
    let height = document.documentElement.scrollTop || document.body.scrollTop;
    if (height > 300) {
        activeShow.value = true;
    } else {
        activeShow.value = false;
    }
}
const back = () => {
    document.documentElement.scrollIntoView({ behavior: "smooth" });
}
</script>
<style scoped lang="less">
.backTop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 10px rgb(101, 168, 223);
    border-radius: 50%;
    background: #fff;
    text-align: center;
    line-height: 50px;
    color: rgb(10, 84, 146);
    font-size: 25px;
    font-weight: lighter;
    cursor: pointer;
    z-index: 20000;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        background: url('../../assets/images/top.png') no-repeat;
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        display: inline-block;
        opacity: 0.5;
    }
}
</style>