<template>
  <header class="app-header">
    <div class="container">
      <RouterLink to="/" class="logoBox">
        <h1 class="logo"></h1>
        <div class="logoText">
          <!-- <h2>医康联大健康</h2> -->
          <!-- <span
            >YIKANGLIANDAJIANKANG</span
          > -->
        </div>
      </RouterLink>

      <!-- 使用头部导航组件 -->
      <div class="search">
        <i class="iconfont icon-search"></i>
        <input type="text" placeholder="搜一搜" />
        <span>确定</span>
      </div>
      <!-- 使用头部购物车组件 -->
      <AppHeaderCart />
      <!-- <AppScan></AppScan> -->
    </div>
  </header>
</template>

<script>
import AppHeaderCart from "./app-header-cart";
import AppScan from "./app-scan";
export default {
  name: "AppHeader",
  components: { AppHeaderCart, AppScan }
};
</script>

<style scoped lang="less">
.app-header {
  background: #d90d0d;
  height: 130px;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
  .logoBox {
    display: flex;
    align-items: center;
    .logo {
      width: 260px;
      height: 90px;
      display: flex;
      flex-direction: column;
      display: block;
      text-indent: -9999px;
      background: url(../assets/home_index/logo.png) no-repeat;
      background-size: 100% 100%;
    }
    .logoText {
      margin-left: 10px;
      h2 {
        margin: 0;
        font-size: 32px;
        font-family: PangMenZhengDao-3, PangMenZhengDao;
        font-weight: normal;
        color: #ffffff;
      }
      span {
        font-size: 14px;
        font-family: PangMenZhengDao-3, PangMenZhengDao;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }

  .search {
    width: 380px;
    height: 32px;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    line-height: 32px;
    background: #ffffff;
    border-radius: 21px;
    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }
    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
    span {
      padding: 0px 22px;
      color: #fff;
      background: @xtxColor;
      border-radius: 21px;
      position: absolute;
      right: 5px;
      top: 2px;
      bottom: 2px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
