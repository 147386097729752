<template>
  <div class="cart">
    <!-- 购物车图标 -->
    <RouterLink class="curr" to="/cart">
      <i class="iconfont icon-cart"></i>
      <!-- <em>{{$store.getters['cart/validTotal']}}</em> -->
      <em>{{totalNum}}</em>
    </RouterLink>
    <!-- 购物车弹出层 -->
    <div class="layer" v-if="$store.getters['cart/validTotal']>0&&$route.path!=='/cart'">
      <div class="list">
        <div class="item" v-for="goods in $store.getters['cart/validList']" :key="goods.skuId">
          <RouterLink :to="`/product/${goods.id}`">
            <img :src="goods.image" alt="" />
            <div class="center">
              <p class="name ellipsis-2">{{goods.proName}}</p>
              <!-- <p class="attr ellipsis">{{goods.attrsText}}</p> -->
            </div>
            <div class="right">
              <p class="price">&yen;{{goods.price}}</p>
              <p class="count">x{{goods.cartNum}}</p>
            </div>
          </RouterLink>
          <i @click="deleteCart(goods.id)" class="iconfont icon-close-new"></i>
        </div>
      </div>
      <div class="foot">
        <div class="total">
          <p>共 {{$store.getters['cart/validTotal']}} 件商品</p>
          <p>&yen;{{$store.getters['cart/validAmount']}}</p>
        </div>
        <XtxButton @click="$router.push('/cart')" type="plain">去购物车结算</XtxButton>
      </div>
    </div>
  </div>
</template>
<script>
import { getCartTotalNum } from '@/api/home'
import { ref } from 'vue'
import { useStore } from 'vuex'
// import Message from './library/Message'
export default {
  name: 'AppHeaderCart',
  setup () {
    const store = useStore()
    store.dispatch('cart/findCart').then(() => {
      // Message({ type: 'success', text: '更新本地购物车成功' })
    })

    // 删除函数
    const deleteCart = (skuId) => {
      store.dispatch('cart/deleteCart', {ids:[skuId]})
    }

    const totalNum = ref(0)
    getCartTotalNum().then((res)=>{
      // console.log(res);
      totalNum.value = res.data.count
    })

    return { deleteCart,totalNum }
  }
}
</script>
<style scoped lang="less">
.cart {
  width: 50px;
  // 加下限制
  position: relative;
  z-index: 600;
  .curr {
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: relative;
    display: block;
    .icon-cart {
      font-size: 22px;
    }
    em {
      font-style: normal;
      position: absolute;
      right: 0;
      top: 0;
      padding: 1px 6px;
      line-height: 1;
      background: @helpColor;
      color: #fff;
      font-size: 12px;
      border-radius: 10px;
      font-family: Arial;
    }
  }
  // 鼠标经过显示弹层
  &:hover {
    .layer {
      opacity: 1;
      transform: none
    }
  }
  // 弹层样式
  .layer {
    opacity: 0;
    transition: all .4s .2s;
    transform: translateY(-200px) scale(1, 0);
    width: 400px;
    height: 400px;
    position: absolute;
    top: 50px;
    right: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    background: #fff;
    border-radius: 4px;
    padding-top: 10px;
    &::before {
      content: "";
      position: absolute;
      right: 14px;
      top: -10px;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: scale(0.6,1) rotate(45deg);
      box-shadow: -3px -3px 5px rgba(0,0,0,0.1);
    }
    .foot {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 70px;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background: #f8f8f8;
      align-items: center;
      .total {
        padding-left: 10px;
        color: #999;
        p {
          &:last-child {
            font-size: 18px;
            color: @priceColor;
          }
        }
      }
    }
  }
  .list {
    height: 310px;
    overflow: auto;
    padding: 0 10px;
    &::-webkit-scrollbar{
      width:10px;
      height:10px;
    }
    &::-webkit-scrollbar-track{
      background: #f8f8f8;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb{
      background: #eee;
      border-radius:10px;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: #ccc;
    }
    .item {
      border-bottom: 1px solid #f5f5f5;
      padding: 10px 0;
      position: relative;
      i {
          position: absolute;
          bottom: 38px;
          right: 0;
          opacity: 0;
          color: #666;
          transition: all .5s;
      }
      &:hover {
        i {
          opacity: 1;
          cursor: pointer;
        }
      }
      a {
        display: flex;
        align-items: center;
        img {
          height: 80px;
          width: 80px;
        }
        .center {
          padding: 0 10px;
          width: 200px;
          .name {
            font-size: 16px;
          }
          .attr {
            color: #999;
            padding-top: 5px;
          }
        }
        .right {
          width: 100px;
          padding-right: 20px;
          text-align: center;
          .price {
            font-size: 16px;
            color: @priceColor;
          }
          .count {
            color: #999;
            margin-top: 5px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
