<template>
  <div class="navContainer">
    <div class="container">
      <ul class="app-header-nav">
        <!-- <li class="home"><RouterLink to="/">首页</RouterLink></li> -->
        <li
          :class="{ active: isActive(item) }"
          v-for="item in list"
          :key="item.id"
          @mouseenter="item.id === 'zyjnxl' ? showSubMenu(item) : null"
          @mouseleave="hideSubMenu(item)"
        >
          <RouterLink :to="item.path">
            <img
              :src="isActive(item) ? item.imgSrc : item.activeImgSrc"
              :alt="item.name"
            />
            {{ item.name }}
            <img 
            v-if="item.activeImgSrc2"
            style="width: 20px;height: 22px;margin-left: 1px;margin-bottom: 3px;"
            :src="!isActive(item) ? item.imgSrc2 : item.activeImgSrc2" 
            :alt="item.name">
          </RouterLink>
          <div class="layer" :class="{ open: item.open}">
          <ul>
            <li v-for="sub in item.children" :key="sub.id">
              <RouterLink @click="hide(item)" :to="`/category/sub/${sub.id}`">
                <span>{{ sub.name }}</span>
              </RouterLink>
            </li>
          </ul>
        </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  name: "AppHeaderNav",
  setup() {
    const store = useStore();
    // 拿到vuex中的分类列表
    const list = computed(() => {
      return store.state.category.list;
    });
    const route = useRoute();
    // 跳转的时候不会关闭二级类目，通过数据来控制
    // 1. vuex每个分类加上open数据
    // 2. vuex提供显示和隐藏函数，修改open数据
    // 3. 组件中使用vuex中的函数，使用时间来绑定，提供一个类名显示隐藏的类名
    const show = item => {
      store.commit("category/show", item.id);
    };
    const hide = item => {
      store.commit("category/hide", item.id);
      
    };
    const isActive = (item) => {
      return route.matched.some(e => {
        return e.path == item.path
      })
    }

    const showSubMenu = item => {
      item.open = true; // 显示二级类目
    };
    const hideSubMenu = item => {
      item.open = false; // 隐藏二级类目
    };

    return { list, show, hide, isActive ,showSubMenu,hideSubMenu};
  }
};
</script>
<style scoped lang="less">
.navContainer {
  width: 100%;
  background: #fff;
}
.app-header-nav {
  display: flex;
  height: 50px;
  position: relative;
  z-index: 999;
  > li {
    margin-right: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 20px;
    > a {
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      display: inline-block;
      color: @xtxColor;
    }
    &.active {
      > a {
        color: #fff;
      }
      background: @xtxColor;
      // 显示二级类目
      // > .layer {
      //   height: 132px;
      //   opacity: 1;
      // }
    }
  }
}
// 二级类名弹层
.layer {
  &.open {
    height: 180px;
    opacity: 1;
  }
  width: 200px;
  background-color: #fff;
  position: absolute;
  left: 340px;
  top: 56px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.2s 0.1s;
  ul {
    display: flex;
    flex-wrap: wrap;
    // padding: 0 70px;
    align-items: center;
    height: 180px;
    li {
      width: 200px;
      height: 60px;
      text-align: center;
      align-items: center;
      display: flex;
      padding: 10px;
      font-size: 16px;
      &:hover {
        span {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
